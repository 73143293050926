import { Branding } from '../../branding.interface';

export const ASPIRE_BRANDING: Branding = {
  termsAndConditionsURL: 'https://healthaspire.com/terms-and-conditions',
  privacyStatementURL: 'https://healthaspire.com/privacy-statement',
  pageTitle: 'Aspire Health Solutions | Powered by CarePay',
  support: 'freshdesk',
  supportFreshdeskFormId: 103000208509,
  supportFreshdeskWidgetId: 103000006312,
  supportFreshworksURL: 'https://euc-widget.freshworks.com/widgets',
  healthTab: true,
  heidiProviderTab: true,
  providersTab: false,
};
