import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MemberGuard } from '@feature/member';

const routes: Routes = [
  {
    path: 'tabs/home',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./home/home.module').then((m: typeof import('./home/home.module')) => m.HomePageModule),
      },
    ],
  },
  {
    path: 'tabs/health',
    loadChildren: () =>
      import('./health/health.module').then((m: typeof import('./health/health.module')) => m.HealthPageModule),
    canActivate: [MemberGuard],
  },
  {
    path: 'tabs/clinics',
    loadChildren: () =>
      import('./clinics/clinics.module').then((m: typeof import('./clinics/clinics.module')) => m.ClinicsPageModule),
    canActivate: [MemberGuard],
  },
  {
    path: 'tabs/providers',
    loadChildren: () =>
      import('@feature/my-providers').then((m: typeof import('@feature/my-providers')) => m.ProvidersLandingModule),
    canActivate: [MemberGuard],
  },
  {
    path: 'tabs/account',
    loadChildren: () =>
      import('./account/account.module').then((m: typeof import('./account/account.module')) => m.AccountPageModule),
  },
  {
    path: 'product',
    loadChildren: () =>
      import('@feature/my-products').then((m: typeof import('@feature/my-products')) => m.ProductModule),
    canActivate: [MemberGuard],
  },
  {
    path: 'claim',
    loadChildren: () => import('@feature/my-claim').then((m: typeof import('@feature/my-claim')) => m.ClaimModule),
    canActivate: [MemberGuard],
  },
  {
    path: 'preauthorization',
    loadChildren: () =>
      import('@feature/my-preauthorization').then(
        (m: typeof import('@feature/my-preauthorization')) => m.PreauthorizationModule,
      ),
    canActivate: [MemberGuard],
  },
  {
    path: 'messages',
    loadChildren: () =>
      import('@feature/my-messages').then((m: typeof import('@feature/my-messages')) => m.MessagesModule),
    canActivate: [MemberGuard],
  },
  {
    path: '',
    redirectTo: '/tabs/home',
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
