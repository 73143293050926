import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiError } from '@data-access/api/error';
import { UISnackbarService } from '@ui/components';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly authService: AuthService,
    private readonly snackbar: UISnackbarService,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getToken$().pipe(
      switchMap((token: string | null) => {
        if (token) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
          });
        }
        return next.handle(request).pipe(
          catchError((err: ApiError) => {
            if (err.originalError instanceof HttpErrorResponse && err.originalError.status == 401) {
              const isLockedOut: boolean = err.originalError?.error?.detail === 'error.user.lockedOut';
              if (!isLockedOut) {
                this.snackbar.showNeutral('Your session has expired, please log in again');
              }
              this.authService.logout();
            }
            return throwError(() => err);
          }),
        );
      }),
    );
  }
}
