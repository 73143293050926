import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FILE_LIMIT_SIZE_MB } from '../../select-files.constants';

@Component({
  selector: 'ui-file-item',
  styleUrls: ['./file-item.component.scss'],
  templateUrl: './file-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UIFileItemComponent {
  @Input() file!: File;
  @Input() tooLarge: boolean = false;
  @Output() readonly clickEvent: EventEmitter<void> = new EventEmitter<void>();

  readonly MAX_FILE_SIZE_MB: number = FILE_LIMIT_SIZE_MB;

  click(): void {
    this.clickEvent.emit();
  }
}
