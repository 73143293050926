<div class="ui-chip d-flex justify-content-center align-items-center">
  <div class="ui-chip__icon d-flex">
    <ng-content select="[ui-prefix]" />
  </div>
  <div class="ui-chip__label d-flex"><ng-content select="span" /></div>
  @if (showDeleteButton) {
    <button class="ui-chip__delete d-flex" (click)="deleteEvent.emit()">
      <ui-icon icon="close-filled" [sizeInPixels]="24" />
    </button>
  }
</div>
