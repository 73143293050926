/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import { DOCUMENT as DOC } from '@angular/common';
import { SafeArea } from '@capacitor-community/safe-area';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import { CookiesService } from '@feature/analytics';
import { BrandingService } from '@feature/branding';
import { FreshdeskService } from '@feature/freshdesk';
import { NavController } from '@ionic/angular';
import { EnvironmentService } from '@util/environment';

@Component({
  selector: 'shell-root',
  templateUrl: 'app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private readonly freshdeskService: FreshdeskService,
    @Inject(DOC) private readonly doc: Document,
    private readonly renderer: Renderer2,
    private readonly navCtrl: NavController,
    private readonly env: EnvironmentService,
    private readonly cookiesService: CookiesService,
    private readonly brandingService: BrandingService,
  ) {
    this.listenToKeyboardChanges();
  }

  ngOnInit(): void {
    StatusBar.setStyle({ style: Style.Dark }).catch(() => {});

    if (this.brandingService.branding().support === 'freshdesk') {
      this.freshdeskService.initFreshdeskScripts(this.renderer, this.doc);
    }

    this.brandingService.initBranding();

    this.listenForNavigationEventsForHeidiIntegration();

    this.cookiesService.openOverlayIfRequired();

    SplashScreen.hide();

    // Set the status bar color after a delay as it's quite buggy on Android. On iOS, setBackgroundColor is not supported.
    setTimeout(() => {
      SafeArea.enable({
        config: {
          customColorsForSystemBars: true,
          statusBarColor: '#00000000', // transparent
          statusBarContent: 'light',
          navigationBarColor: '#00000000', // transparent
          navigationBarContent: 'light',
        },
      });
    }, 300);
  }

  ngOnDestroy(): void {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.removeAllListeners();
    }
  }

  private listenForNavigationEventsForHeidiIntegration(): void {
    window.addEventListener(
      'message',
      (event: MessageEvent<any>) => {
        // Needed for HEIDI widget to navigate to the providers page
        if (
          event.origin === this.env.environment.iframes.heidiOrigin &&
          event.data &&
          event.data.type === 'navigate' &&
          event.data.target === 'providers'
        ) {
          this.navCtrl.navigateRoot(['tabs', 'clinics'], { queryParams: { ...(event.data.queryParams ?? {}) } });
        }

        // Needed for HEIDI Health widget to open a browser to start video calling
        if (
          event.origin === this.env.environment.iframes.heidiOrigin &&
          event.data &&
          event.data.type === 'open_browser' &&
          event.data.target
        ) {
          Browser.open({
            url: event.data.target,
          });
        }
      },
      false,
    );
  }

  private listenToKeyboardChanges(): void {
    if (Capacitor.isPluginAvailable('Keyboard')) {
      Keyboard.addListener('keyboardWillShow', this.setClass);
      Keyboard.addListener('keyboardWillHide', this.removeClass);
    }
  }

  private readonly setClass = () => document.body.classList.add('keyboard-shown');
  private readonly removeClass = () => document.body.classList.remove('keyboard-shown');
}
