@if (filesForm) {
  <form [formGroup]="filesForm" (ngSubmit)="onSubmit()">
    <input
      #file
      hidden
      type="file"
      (change)="onFileSelected(file.files)"
      multiple
      accept="application/pdf,image/webp,image/jpeg,image/jpg,image/png"
    />
    <div class="pb-3 mt-4">
      <div class="d-flex justify-content-center align-items-center file-upload pt-3 pb-3 mb-3" (click)="file.click()">
        <ui-icon icon="upload" class="me-1" />
        <div class="file-upload__label ms-1">Add a file</div>
      </div>
      <p class="assistive-text mb-0">
        Supports pdf, png, jpeg, webp.
        <br />
        Max file size for all attachments combined is {{ FILE_LIMIT_SIZE_MB }} MB.
      </p>
    </div>
    @for (file of filesToBigForUpload(); track file; let index = $index) {
      <div class="pb-3">
        <ui-file-item [file]="file" [tooLarge]="true" (clickEvent)="onDeleteFileToBigForUpload(index)" />
      </div>
    }
    @for (file of filesForUpload(); track file; let index = $index) {
      <div class="pb-3">
        <ui-file-item [file]="file" (clickEvent)="onDeleteFile(index)" />
        <ui-form-field>
          <select [formControl]="fileTypesControl.at(index)" [name]="'file-' + index">
            <option value="" disabled selected>Select file type</option>
            @for (fileType of fileTypes; track fileType) {
              <option [value]="fileType.value">{{ fileType.label }}</option>
            }
          </select>
          @if (hasFileTypeError(index)) {
            <ui-form-field-error>Please select a file type</ui-form-field-error>
          }
        </ui-form-field>
      </div>
    }
    @if (hasNoFileUploadedError) {
      <div class="validation d-flex align-items-center">
        <ui-icon icon="warning" color="red" class="me-2" />
        Please upload
        @if (necessaryFilesTypes.length === 0) {
          a file.
        }
        @for (necessaryFilesType of necessaryFilesTypes; track necessaryFilesType; let last = $last) {
          {{
            last && necessaryFilesTypes.length > 1
              ? 'and ' + necessaryFilesType.label + '.'
              : last
                ? necessaryFilesType.label + '.'
                : necessaryFilesType.label + ', '
          }}
        }
      </div>
    }
    @if (hasMissingFileError) {
      <div class="validation d-flex align-items-center">
        <ui-icon icon="warning" color="red" class="me-2" />
        @for (necessaryFilesType of necessaryFilesTypes; track necessaryFilesType; let last = $last) {
          {{
            last && necessaryFilesTypes.length > 1
              ? 'and ' + necessaryFilesType.label
              : last
                ? necessaryFilesType.label
                : necessaryFilesType.label + ', '
          }}
        }
        <ng-container> {{ necessaryFilesTypes.length > 1 ? ' are' : ' is' }} required. </ng-container>
      </div>
    }
    @if (hasExceedsRequiredAmountError) {
      <div class="validation d-flex align-items-center">
        <ui-icon icon="warning" color="red" class="me-2" />
        <div>
          Please select only
          @for (necessaryFilesType of necessaryFilesTypes; track necessaryFilesType; let last = $last) {
            {{
              last && necessaryFilesTypes.length > 1
                ? 'and ' + necessaryFilesType.value.amount + ' of ' + necessaryFilesType.label + '.'
                : last
                  ? necessaryFilesType.value.amount + ' of ' + necessaryFilesType.label + '.'
                  : necessaryFilesType.value.amount + ' of ' + necessaryFilesType.label + ', '
            }}
          }
        </div>
      </div>
    }
    @if (hasExceedsCombinedFileSizeError) {
      <div class="validation d-flex align-items-center">
        <ui-icon icon="warning" color="red" class="me-2" />
        <div>
          The maximum file size for all attachments combined is {{ FILE_LIMIT_SIZE_MB }} MB. Please remove some files.
        </div>
      </div>
    }
  </form>
}
