import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIButtonModule } from '../button';
import { UIIconModule } from '../icon';
import { UIFormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { UIFormFieldHintComponent } from './components/form-field-hint/form-field-hint.component';
import { UIFormFieldComponent } from './components/form-field/form-field.component';

@NgModule({
  declarations: [UIFormFieldComponent, UIFormFieldErrorComponent, UIFormFieldHintComponent],
  exports: [UIFormFieldComponent, UIFormFieldErrorComponent, UIFormFieldHintComponent],
  imports: [CommonModule, UIIconModule, UIButtonModule],
})
export class UIFormFieldModule {}
